export default {
  Blizzard: 'Накладывается на центральную клетку. Отнимает 3 здоровья у вражеских и соююзных воинов в этой шеренге при наложении и как ПОСЛЕДНИЙ ВЫЗОВ',
  Frostbite: 'Накладывается на вражеского воина и отнимает у него -2 базовой атаки',
  FrostTrap: 'РЕАКЦИЯ: Вражеский воин, который только сделал шаг или вошел в игру, получает повреждение силой 3 и оглушение',
  IceBlock: 'Кладется на союзного героя. Поглощает весь урон по герою в течение хода врага',
  IceSpikes: 'Кладется рядом с линией и атакует силой 2 всех воинов в линии при наложении. Атакует силой 2 воинов, которые входят в игру или перемещаются в линию',
  IceWall: 'Кладется на центральную клетку. ВСЕ Стрелки и Бойцы в этой шеренге не могут атаковать в свой ход',
  IceWeapon: 'Накладывается на союзного воина. Он получает +2 к базовой атаке',
  Icing: 'Кладется на центральную клетку. ВСЕ Воины в этой шеренге не могут передвигаться и атаковать в свой ход',
  Tsunami: 'Отнять 7 здоровья у ВСЕХ не Летающих воинов на поле боя',
  Waterfall: 'Кладется рядом с полем боя. Вражеские воины с текущей базовой атакой 2 или меньше не могут атаковать в свой ход',
  IceArrow: 'Отнять 2 здоровья у вражеского воина, этот воин получает ОГЛУШЕНИЕ',
  IceArmor: 'Накладывается на союзного воина или героя. Весь урон по этому воину снижается на 1 (в том числе от ответного удара)',
};
