export default {
  FireArrow: 'Атковать воина силой 2',
  MeteorShower: 'Атакует силой 3 ВСЕХ воинов в шеренге и ВСЕХ воинов в соседней шеренге (приоритет - верхняя шеренга)',
  FireBall: 'Атаковать воина силой 3 или 4, если вы только что сыграли карту заклинания из руки',
  PowerOfFire: 'Кладется рядом с полем боя. Все ваши воины получают +2 к базовой атаке до конца вашего хода',
  Rage: 'Накладывается на воина, он получает + 1 к атаке',
  ExplosiveTrap: 'РЕАКЦИЯ. Кладется рядом с полем. Воин, который только что переместился или вошел в игру, атакуется силой 2, а также все прилегающие к нему воины',
  FireSplash: 'Атаковать воина и прилегающих к нему всех других воинов силой 1',
  FireShield: 'Вражеские воины, при атаке воина с огненным щитом в свой ход, получают физический урон равный силе этой атаки',
  FireBlast: 'Атаковать воина и прилегающих к нему всех других воинов силой 4',
  Ignition: 'Накладывается на воина и атакует его силой 2 при на ложении и как ПОСЛЕДНИЙ ВЫЗОВ. Если убивает воина при наложении, то остается на этой клетке и блокирует ее',
  Blindness: 'Накладывается на воина и он не может атаковать, передвигаться в свой ход пока не получит урон от воинов',
};
