export default {
  ThunderBlast: 'Атаковать воина силой 4 и оглушить союзных и вражеских воинов прилегающих к цели',
  Swiftness: 'Накладывается на союзного воина. Он может атаковать и ходить в один ход',
  LightningStrike: 'Атаковать воина силой 4 или 5, если есть ваши наложенные заклинания воздуха на поле',
  Dodge: 'РЕАКЦИЯ. Герой игнорирует первый урон по нему за раунд',
  ShockWaveCast: 'Выберите пустую клетку для применения',
  LightningPower: 'Накладывается на вашего воина. Он получает СТРЕМИТЕЛЬНОСТЬ и ПРОНИКАЮЩАЮ АТАКУ 2',
  ThunderWeapon: 'Накладывается на воина, он получает УДАРНУЮ ВОЛНУ 2',
  ArrowsRain: 'Кладется рядом с полем. Ваши Стрелки могут атаковать любого воина на поле',
  AirFlows: 'Можно переместить целевого вашего воина и другого союзного воина, стоящего в этой шеренге на свободную клетку',
};
