export default {
  ArcaneArmor: 'РЕАКЦИЯ: Во время хода врага, поглотить урон от первой атаки воинов силой 3 и более по вашему воину или герою',
  CursedChackle: 'Накладывается на вражеского воина. После того как этот воин нанес урон в свой ход, нанести такой же урон вражескому герою',
  InvisibilityCloak: 'Накладывается на союзного воина. Он получает НЕВИДИМОСТЬ',
  SoulDrain: 'Уничтожить вражеского воина на поле боя',
  Teleport: 'Переместить союзного воина на любую свободную клетку (подходящую ему)',
  TownPortal: 'Вражеский воин, а также наложенные на него заклинания, отправляются в руки к владельцам в изначальном состоянии',
  Transformation: 'Вражеский воин получает ОГЛУШЕНИЕ и вы можете передвинуть его на соседнюю клетку',
  MagicMist: 'Накладывается на вражеского воина, он получает НЕВИДИМОСТЬ',
};
